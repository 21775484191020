// ============================================================================
// productService
// -----------------------
// product module related services
// ============================================================================
// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/SuperAdminProductApi'
// ---------
// Internals
// ---------
const Private = {
    requests: {
        product: {
            create: API.sa.product.create,
            read: API.sa.product.read,
            replace: API.sa.product.replace,
            delete: API.sa.product.delete
        },
        products: {
            create: NotImplementedYet,
            read: API.sa.products.read,
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    },
    service: {
        product: {
            create: function (data) {
                RequiredArguments({ data: data })
                return Private.requests.product.create(data)
            },
            read: function (productId) {
                RequiredArguments({ productId: productId })
                return Private.requests.product.read(productId)
            },
            replace: function (productId, data) {
                RequiredArguments({ productId: productId, data: data })
                return Private.requests.product.replace(productId, null, data)
            },
            delete: function (productId) {
                RequiredArguments({ productId: productId })
                return Private.requests.product.delete(productId)
            },
        },
        products: {
            create: NotImplementedYet,
            read: function (params = {}) {
                return Private.requests.products.read(params)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    },
}
// -------
// Exports
// -------
export default {
    createProduct: Private.service.product.create,
    getProduct: Private.service.product.read,
    replaceProduct: Private.service.product.replace,
    deleteProduct: Private.service.product.delete,
    getProducts: Private.service.products.read
}