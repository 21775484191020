// ============================================================================
// SuperAdminProductApi
// -------------------
// SuperAdminProduct api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
    sa: {
        product: {
            create: function (data, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ data: data })
                return Backend.POST(URLS.api.sa.products.uri, [], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            read: function (productId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ productId: productId })
                return Backend.GET(URLS.api.sa.products.uri, [productId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            replace: function (productId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ productId: productId,  data: data })
                return Backend.PUT(
                    URLS.api.sa.products.uri,
                    [productId],
                    params,
                    data,
                    doCancelPreviousRequest,
                    doCancelAllOtherRequests
                )
            },
            delete: function (productId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ productId: productId })
                return Backend.DELETE(URLS.api.sa.products.uri, [productId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
        },
        products: {
            create: NotImplementedYet,
            read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                return Backend.GET(URLS.api.sa.products.uri, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    }
}