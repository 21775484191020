
<template>
	<div class="cp-invoiced-products">
		<div
			v-for="(_, index) in accountingFirmProducts.items"
			:key="index"
			class="d-flex align-center justify-space-between"
		>
			<v-autocomplete
				v-model="accountingFirmProducts.items[index]"
				class="cp-invoiced-products__product-line"
				:items="availableProducts.items"
				item-text="name"
				item-value="id"
				:label="$t('sa.accounting-firms.product')"
			/>
			<v-btn
				:title="$t('actions.delete')"
				icon class="mx-0 px-0"
				@click="deleteProduct(index)"
			>
				<v-icon color="primary">delete</v-icon>
			</v-btn>
		</div>
		<v-autocomplete
			v-if="showNewProductLine && canAddNewProduct"
			v-model="newProduct"
			:items="availableProducts.items"
			item-text="name"
			item-value="id"
			:label="$t('sa.accounting-firms.product')"
			@change="addNewProduct"
			@blur="onBlur"
		/>
		<w-button
			v-if="canAddNewProduct"
			:flat="false"
			:disabled="showNewProductLine"
			class="cp-invoiced-products__add-product"
			@click="addNewProductLine"
		>
			{{ $t('sa.accounting-firms.add_product') }}
		</w-button>
		<v-messages
			v-show="showValidationErrors && accountingFirmProducts.items.length === 0"
			:value="[$t('required')]"
			dark
			color="error"
		/>
	</div>
</template>

<script>
import SuperAdminAccountingFirmService from "@/services/SuperAdmin/SuperAdminAccountingFirmProductService";
import SuperAdminProductService from "@/services/SuperAdmin/SuperAdminProductService";
import { productState } from "@/components/Group/Manager/Products/productState";
import {
	mapDisabledProducts,
	mapProductIds,
	sortProducts,
} from "@/components/Group/Manager/Products/productMapper";

export default {
	props: {
		accountingFirmId: {
			type: Number,
			required: false,
			default: null,
		},
		value: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			newProduct: null,
			showNewProductLine: true,
			showValidationErrors: false,
			availableProducts: productState(),
			accountingFirmProducts: productState(),
		};
	},
	computed: {
		canAddNewProduct() {
			return this.availableProducts.items.length !== this.accountingFirmProducts.items.length;
		},
	},
	watch: {
		'accountingFirmProducts.items': {
			handler(value) {
				this.availableProducts.items = mapDisabledProducts(this.availableProducts.items, value);
				this.$emit("input", value);
			},
			deep: true
		},
	},
	mounted() {
		this.loadData();
	},
	methods: {
		async fetchAccountingFirmProducts() {
			if (!this.accountingFirmId) {
				return;
			}
			this.accountingFirmProducts.loading = true;
			const data = await SuperAdminAccountingFirmService.getProducts(this.accountingFirmId);
			this.accountingFirmProducts.items = mapProductIds(data);
			this.$emit("initialProductsLoaded", [...this.accountingFirmProducts.items]);
			this.accountingFirmProducts.loading = false;
		},
		async fetchAvailableProducts() {
			this.availableProducts.loading = true;
			this.availableProducts.items = sortProducts(
				mapDisabledProducts(
					await SuperAdminProductService.getProducts(),
					this.accountingFirmProducts.items
				)
			);
			this.availableProducts.loading = false;
		},
		async loadData() {
			await this.fetchAccountingFirmProducts();
			await this.fetchAvailableProducts();
		},
		addNewProductLine() {
			this.showNewProductLine = true;
		},
		addNewProduct() {
			this.accountingFirmProducts.items.push(this.newProduct);
			this.newProduct = null;
			this.showNewProductLine = false;
		},
		deleteProduct(index) {
			this.accountingFirmProducts.items.splice(index, 1);
		},
		onBlur() {
			this.showValidationErrors = true;
		},
	},
};
</script>

<style scoped>
.cp-invoiced-products__add-product {
	display: block;
	margin-left: auto;
}
.cp-invoiced-products__product-line {
	width: calc(100% - 36px);
}
</style>
