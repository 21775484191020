function productStruct(product) {
  return {
    id: product.id,
    name: product.name,
    disabled: true
  }
}

export function mapDisabledProducts(products, selectedProducts) {
  return products.map(product => {
    return {
      ...productStruct(product),
      disabled: selectedProducts.includes(product.id)
    }
  });
}

export function mapProductIds(products) {
  return products.map(product => product.id);
}

export function sortProducts(products) {
  return products.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
}
